<!--
 * @Description: 用户权限
 * @Author: ChenXueLin
 * @Date: 2021-10-18 10:42:50
 * @LastEditTime: 2022-04-22 14:41:01
 * @LastEditors: LiangYiNing
-->
<template>
  <section
    class="edit-wrapper"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="edit-wrapper__body">
      <div class="treeData">
        <e6-tree
          :list="treeData"
          v-model="roleList"
          :is-check="true"
          :is-open="true"
          issingleleaf
          ref="e6tree1"
          :selectAndCheck="true"
        ></e6-tree>
      </div>
    </div>
  </section>
</template>

<script>
import { printError } from "@/utils/util";
import { getBasic } from "@/api";
import base from "@/mixins/base";

export default {
  name: "",
  components: {},
  data() {
    return {
      loading: false,
      roleList: [] //所拥有的角色权限
    };
  },
  props: ["activeName", "treeData"],
  mixins: [base],
  computed: {},
  watch: {
    activeName(val) {
      if (val == "two") {
        this.queryDetail();
      }
    }
  },
  methods: {
    async queryDetail() {
      let hasParamsRoute = this.getHasParamsRoute();
      let id = hasParamsRoute.params.userId;
      if (!id) {
        return;
      }
      try {
        this.loading = true;
        let res = await getBasic({ id });
        this.roleList = res.data.privilegeId;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.treeData {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
